import React, { useState } from 'react';
import { Card, Box, Heading, Button } from 'theme-ui';
import Section from '@components/Section';
import { graphql, useStaticQuery } from 'gatsby';

const styles = {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        mt: [0, 5],
        maxWidth: 1000,
        marginLeft: 'auto',
        marginRight: 'auto'
      },
      leftColumn: {
        flexBasis: '100%',
        flex: 1,
        position: 'relative',
        zIndex: 3,
      },
      rightColumn: {
        display: 'block',
        mt: 3,
        maxWidth: 500, 
        margin: '0 auto', 
      },
      '@media screen and (min-width: 768px)': {
        // Apply this style for tablets and larger screens
        wrapper: {
          flexDirection: 'row',
          alignItems: 'flex-start',
        },
        leftColumn: {
          flexBasis: '2/3',
        },
        rightColumn: {
          flexBasis: '1/3',
          mt: 0,
        },
      },
    heading: {
        mb: 4,
        span: {
        color: `omegaDark`,
        },
    },
    question: {
        mb: 4,
        textAlign: 'center',
        span: {
        color: `omegaDark`,
        },
    },
    answerButton: {
        mr: 2,
    },
    answerButtonStack: {
        mr: 2,
        marginTop: '5px',
        marginBottom: '5px',
    },
    bigButtonStyle: {
        width: '100%',
        fontWeight: 900,
        fontSize: '26px',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        textTransform: 'uppercase',
        cursor: 'pointer',
        borderRadius: '8px',
        boxShadow: '0px 0px 12px -2px rgba(0, 0, 0, 0.5)',
        transition: 'background-color .6s ease',
        WebkitTransition: 'background-color .6s ease',
        backgroundColor: 'red', 
        '&:hover': {
            backgroundColor: '#f0f0f0', 
            color: '#333', 
        },
    },
    image: (image) => ({
        display: [`none`, null, `block`],
        height: `120%`,
        width: `1/2`,
        minWidth: 380,
        maxHeight: 514,
        borderRadius: `default`,
        backgroundImage: `url(${image})`,
        backgroundRepeat: `no-repeat`,
        overflowX: `hidden`,
        position: `absolute`,
        bottom: 0,
        right: 0,
        zIndex: 2,
    }),
};

const Photo = () => {
    return <img style={{ width: '100%', height: 'auto'}} src={'../quiz/chill.jpeg'} alt="Chiller AC" />
};

const OfferPhoto = () => {
    return <img style={{ width: '100%', height: 'auto'}} src={'../quiz/chillmob.png'} alt="Your Offer!" />
};


const LeftColumn = ({ children }) => <Box sx={styles.leftColumn}>{children}</Box>;

const RightColumn = ({ children }) => <Box sx={styles.rightColumn}>{children}</Box>;

const Wrapper = ({ children }) => (
  <Section>
    <Card variant="paper-lg" sx={styles.wrapper}>
      {children}
    </Card>
  </Section>
);

const QuizComponent = () => {
  const totalQuestions = 3; // Set the total number of questions in the quiz
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [sqft, setSqft] = useState('');
  const [campingPreference, setCampingPreference] = useState('');
  const [favoriteColor, setFavoriteColor] = useState('');

  const handleStateChange = (event) => {
    setSqft(event.target.value);
  };

  const handleCampingPreferenceChange = (preference) => {
    setCampingPreference(preference);
    handleNextQuestion();
  };

  const handleColorChange = (color) => {
    setFavoriteColor(color);
    handleNextQuestion();
  };

  const handleNextQuestion = () => {
    if (currentQuestion < totalQuestions) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      // Handle the case when the user has completed all the questions
      // For example, display a completion message, show a submit button, etc.
      if (campingPreference) {
        handleSeeDiscount();
      } else {
        // Handle the case when the user hasn't selected a color yet
        // You may display an error message or prompt them to select a color
      }
    }
  };

  const handleSeeDiscount = () => {
    // Perform any final actions or calculations before redirecting to the discount link
    window.open('https://track.dealextremist.com/click/1', '_blank');
  };

  return (
    <Wrapper>
      <Box sx={styles.wrapper}>
        <LeftColumn>
            
            {currentQuestion === 1 && (
            <Section>
                <Heading variant="h1" sx={styles.heading}>
                You're 2 steps away from saving <span>HUNDREDS</span> a month in cooling costs!
                </Heading>
                <Photo />
                <Box sx={styles.question}>
                    <h3>How much on average is your electric bill each month?</h3>
                    <Button variant="primary" sx={styles.answerButtonStack} onClick={() => handleNextQuestion('red')}>
                        $50 - $100
                    </Button>
                    <Button variant="primary" sx={styles.answerButtonStack} onClick={() => handleNextQuestion('blue')}>
                        $100 - $200
                    </Button>
                    <Button variant="primary" sx={styles.answerButtonStack} onClick={() => handleNextQuestion('green')}>
                        $200 - $300
                    </Button>
                    <Button variant="primary" sx={styles.answerButtonStack} onClick={() => handleNextQuestion('brown')}>
                        $300+
                    </Button>
                </Box>
            </Section>
            )}

            {currentQuestion === 2 && !campingPreference && (
            <Section>
                <Heading variant="h1" sx={styles.heading}>
                We can help <span>lower</span> that bill.
                </Heading>
                <Box sx={styles.question}>
                    <p>One more question:</p>
                    <h3>Do you live in an area with high humidity?</h3>
                    <Button variant="primary" sx={styles.answerButtonStack} onClick={() => handleCampingPreferenceChange('yes')}>
                        Yes
                    </Button>
                    <Button variant="primary" sx={styles.answerButtonStack} onClick={() => handleCampingPreferenceChange('no')}>
                        No
                    </Button>
                    <Button variant="primary" sx={styles.answerButtonStack} onClick={() => handleCampingPreferenceChange('no idea')}>
                        I Don't Know
                    </Button>
                </Box>
            </Section>
            )}

            {currentQuestion === 3 && campingPreference && (
            <Section>
                <Heading variant="h1" sx={styles.heading}>
                    <span>Congratulations 🎉 </span><br/>We found a great discount code for you!
                </Heading>
                <Box sx={styles.question}>
                    <a target="_blank" href="https://track.dealextremist.com/click/1"><OfferPhoto /></a>
                    <h3>Make sure to claim your extra 10% discount:</h3>
                    <Button sx={styles.bigButtonStyle} onClick={handleSeeDiscount}>Claim Yours Today!</Button>
                </Box>    
            </Section>
            )}
        
        </LeftColumn>
        <RightColumn>
            
        </RightColumn>
      </Box>
    </Wrapper>
  );
};

export default QuizComponent;